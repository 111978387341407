import methods, {ApiHostType} from '../buildMethods';

/**
 * This is a tad more setup code, but we shouldn't be
 * returning the whole object for unrelated calls.
 *
 * ex:
 * cart.removePlan() :: returns the curried pathing. All that is different is that you are
 * initializing at the time you need it.
 * */
const m = methods();
/* Initial session cart/user */
export const server = {
  initial: m.get('/api/clients/server/initial'),
};

/* Cart related apis */
export const cart = {
  selectPlan: m.post('/api/clients/carts/select_plan'),
  removePlan: m.destroy('/api/clients/carts/remove_plan'),
  estimatePlan: m.post('/api/clients/carts/estimate_plan'),
  canAddService: m.post('/api/clients/carts/can_add_service'),
  find: m.get('/api/clients/carts'),
  itemPrice: m.post('/api/clients/carts/item_price'),
  addItem: m.post('/api/clients/carts/add_item'),
  updateItem: m.post('/api/clients/carts/update_item'),
  updateQuantity: m.post('/api/clients/carts/update_quantity'),
  addCoupon: m.post('/api/clients/carts/add_coupon'),
  startBooking: m.post('/api/clients/carts/start_booking'),
};

/* SkuTypes(product/services) related apis */
export const skus = {
  all: m.get('/api/clients/skus'),
  allWithImages: m.get('/api/clients/routes/sku_categories'),
  find: m.get('/api/clients/skus/:id'),
  questions: m.get('/api/clients/skus/:id/questions'),
  skuPrice: m.get('/api/clients/skus/:id/price'),
  reviewsPage: m.get('/api/clients/reviews/reviews_page'),
  planHolders: m.get('/api/clients/plans/reviews/plan_holders'),
  totalCompletedServicesAndClientRating: m.get('/api/clients/stats'),
  estimate: m.get('/api/clients/skus/:skuId/estimate'),
};

/* Reviews related apis */
export const reviews = {
  list: m.get('/api/clients/reviews/'),
  sku: m.get('/api/clients/reviews/:id'),
  reviewsPage: m.get('/api/clients/reviews/reviews_page'),
  planHolders: m.get('/api/clients/plans/reviews/plan_holders'),
  totalRatingAndCompletedServices: m.get('/api/clients/stats'),
};

/* Subscriptions related apis */
export const subscriptions = {
  all: m.get('/api/clients/plans'),
  show: m.get('/api/clients/plans/:id'),
  subscribe: m.post('/api/clients/subscriptions/subscribe'),
};

/* User related apis */
export const user = {
  login: m.post('/service/auth/sessions'),
  logout: m.destroy('/logout'),
  current: m.get('/api/clients/users/current'),
  registration: m.post('/api/clients/users'),
  shortRegistration: m.post('/api/clients/users'),
  resetPassword: m.post('/api/clients/users/reset_password'),
  validateEmail: m.get('/api2/clients/validate_email', ApiHostType.API2),
  verifyEmail: m.get('/api/email_checker/verify?email=:email'),
  checkUserEmail: m.get('/api/clients/users/check_user'),
  createPassword: m.post('/api/clients/users/create_password'),
  update: m.post('/api/clients/users/update_profile'),
  // Verifies the structural validity of the email via a third-party service
  validateEmailExternal: m.get('/api/email_checker/verify'),
  /** Check account existence for passwordless login */
  checkAccount: m.post('/api/auth/check'),
  /** Send auth code for passwordless login */
  sendAuthCode: m.post('/api/auth/code'),
  /** Validate auth code for passwordless login */
  validateAuthCode: m.post('/api/auth/validate'),
};

/* Search related apis */
export const search = {
  quickSearch: m.get('/api2/quick_search', ApiHostType.API2),
};

/* SpeedTest related apis */
export const speedTest = {
  sendResults: m.post('/api/clients/stats/speed_test'),
};

/* Geo location */
export const geo = {
  geocode: m.get('/api/geo/geocode'),
};

/* Location related apis */
export const locations = {
  find: m.get('/api/clients/locations?locationPath=:locationPath'),
  getTechCoverageByZip: m.post('/api/clients/carts/check_zip'),
};

/* Home Hub */
export const homeHub = {
  getShortSurveyList: m.get('/api/clients/surveys'),
  getExpandedSurveyList: m.get('/api/clients/surveys/:seoName'),
  getAllSurveysByHub: m.get('/api/clients/hubs/:hid/surveys'),
  getSurveyAnswersBySurveyType: m.get('/api/clients/hubs/:hid/results/:seoName'),
  getAllHomeHubs: m.get('/api/clients/hubs'),
  createHomeHub: m.post('/api/clients/hubs'),
  getHomeHub: m.get('/api/clients/hubs/:hid'),
  deleteHomeHub: m.destroy('/api/clients/hubs/:id'),
  updateHomeHubSurvey: m.put('/api/clients/hubs/:hid/surveys/:seoName'),
  getHomeHubSurveys: m.get('/api/clients/hubs/:id/surveys'),
  getHomeHubSurvey: m.get('/api/clients/hubs/:hub_id/surveys/:id'),
  getRecommendations: m.get('/api/clients/hubs/:hub_id/surveys/:seoName/recommendations'),
};

/* Lead Generation */
export const tracking = {
  leadGeneration: m.post('/api/segment/identify'),
};

/* Landing Pages */
export const landings = {
  find: m.get('/api/clients/landings/:name'),
};

/* Workflows */
export const workflows = {
  last: m.get('/api/clients/workflows'),
};

/* Store Credits */
export const credits = {
  claim: m.post('/api/clients/credits'),
};
