import Bugsnag from '@bugsnag/js';
import {getRailsHost} from '@utils/route/routesByEnvironment';

function removeNonValues<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const makePath = (path: string, external: boolean = true) => {
  if (/(https?|localhost)/.test(path)) {
    return () => path;
  }

  if (!path.includes('::')) {
    return () => (external ? `${process.env.NEXT_PUBLIC_HOST_URL_CLIENT}${path}` : path);
  }
  return function replaceParams(...args: (string | null)[]) {
    if (args.includes(null)) {
      Bugsnag.notify(new Error(`Error in makePath: null args. ${path} ${args}`));
    }

    const result = args.filter(removeNonValues).reduce((link, arg) => link.replace(/::\w+/, (arg || '0').toString()), path);

    if (result.includes('::')) {
      throw new Error(`Please provide all replacement for a path: ${path} - ${result}`);
    }

    return external ? `${process.env.NEXT_PUBLIC_HOST_URL_CLIENT}${result}` : result;
  };
};

/**
 * Internal/External link pathing. Because we are slowly moving to nextjs, we need to have the
 * concept of an internal/external routing. - 2nd arg asks this, default is true (is external).
 *
 * @type {{(): string; (...args: string | null[]): string | TValue}}
 */

export const accountPath = makePath('/account');
export const accountProfilePath = makePath('/account/profile');
export const accountDevicesPath = makePath('/account/devices');
export const accountScoreCardPath = makePath('/account/scorecard');
export const addSkuPath = makePath('/cart/add-sku/::id', false);
export const cancellationPolicyPath = makePath('/cancellation-policy', false);
export const cookiesPath = makePath('/cookies', false);
export const cartPath = makePath('/cart');
export const checkoutVerificationPath = makePath('/cart/booking/verification');
export const clientsRegistrationPath = makePath('/clients/register', false);
export const directBookingTechProfilePath = makePath('/schedule/::techId');
export const faqMembershipPath = makePath('/faq?src=membershipGuarantee', false);
export const faqPath = makePath('/faq', false);
export const homePath = makePath('/', false); // false
export const locationsRootPath = makePath('/us', false);
export const loginPath = makePath('/login'); // false
export const orderStatusPath = makePath('/orderstatus');
export const plansPath = makePath('/plans', false);
export const privacyPath = makePath('/privacy', false);
export const productPath = makePath('/products/::seoName', false);
export const productsIframeBasePath = makePath('/products/shop', false);
export const productStorePath = makePath('/products/shop', false);
export const railsAdminPath = makePath(`${getRailsHost()}/internal/login`); // false
export const resetPasswordPath = makePath('/clients/reset-password', false);
export const skuCatPath = makePath('/tech-support/for/::seoName', false);
export const skuPath = makePath('/tech-support/::seoName', false);
export const snsPath = makePath('/sns', false);
export const speedTestPage = makePath('/speed', false);
export const tosPath = makePath('/tos', false);
export const meetTheTechsPath = makePath('/meet-our-techs', false);
export const completeOrderPath = makePath('/complete_order');
export const cartVerificationPath = makePath('/cart/verification');
export const evPortalPath = makePath('/ev/portal/::workflowToken');

/* NON-USED */
export const baseSurveysPath = makePath('/surveys', false); // false
export const surveyPath = makePath('/surveys/::surveyType', false); // false
export const surveyRecommendationPath = makePath('/surveys/recommendations/::surveyType', false);
export const homeHubPath = makePath('/homehub');
