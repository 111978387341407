import cn from 'classnames';
import styles from '../modal.module.scss';

type LoginPasscodeBoxesProps = {
  passcode: string;
  isInputFocused: boolean;
  hasError: boolean;
  numBoxes: number;
};

export const LoginPasscodeBoxes = ({passcode, isInputFocused, hasError, numBoxes}: LoginPasscodeBoxesProps) => {
  // Create an array of box indices [0, 1, 2, ...]
  const boxIndices = Array.from({length: numBoxes}, (_, i) => i);

  return (
    <>
      {boxIndices.map(index => {
        const isNextEmptyBox = index === passcode.length;
        const isLastBox = index === boxIndices.length - 1;
        const isLastBoxFilled = Boolean(passcode[boxIndices.length - 1]);
        const shouldShowFocus = isInputFocused && (isNextEmptyBox || (hasError && isLastBox && isLastBoxFilled));

        return (
          <div
            key={index}
            className={cn(styles.passcodeBox, {
              [styles.focused]: shouldShowFocus,
            })}
          >
            {passcode[index] || ''}
          </div>
        );
      })}
    </>
  );
};
